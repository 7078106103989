import { createReducer, on } from '@ngrx/store';
import {
  FlashMessagesState,
  initialFlashMessagesState,
} from './flash-messages.types';
import { FlashMessagesActions } from './flash-messages.actions';

export const flashMessagesReducer = createReducer(
  initialFlashMessagesState,
  on(
    FlashMessagesActions.addFlashMessageAction,
    (state: FlashMessagesState, props): FlashMessagesState => {
      const messages = state.flashMessages.filter(
        (fm) =>
          !fm.destroyAfter || (fm.destroyAfter && fm.destroyAfter > Date.now()),
      );
      messages.push({ ...props.flashMessage });
      return {
        ...state,
        flashMessages: messages,
      };
    },
  ),
  on(
    FlashMessagesActions.removeFlashMessageAction,
    (state: FlashMessagesState, props): FlashMessagesState => {
      const queuedMessages = state.flashMessages.filter(
        (fm) =>
          fm.uuid !== props.uuid &&
          (!fm.destroyAfter ||
            (fm.destroyAfter && fm.destroyAfter > Date.now())),
      );
      return {
        ...state,
        flashMessages: queuedMessages,
      };
    },
  ),
);
