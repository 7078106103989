import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {
  BrowserTracing,
  init,
  instrumentAngularRouting,
} from '@sentry/angular';
import { environment } from './environments/environment';

init({
  dsn: environment.sentryDns,
  environment: environment.sentryEnvironment,
  release: environment.version,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', environment.sentryOriginUrl],
      routingInstrumentation: instrumentAngularRouting,
    }),
  ],
  autoSessionTracking: false,
  tracesSampleRate: 1,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
