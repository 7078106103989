export const environment = {
  keycloakUrl: 'https://auth-test.desideria.org',
  keycloakClientId: 'desideria-verwaltung-frontend-test',
  keycloakRealm: 'desideriacare-verwaltung-test',
  keycloakAccountUrl:
    'https://auth-test.desideria.org/realms/desideriacare-verwaltung-test/account',

  // api
  apiUrl: 'https://api-test.desideria.org',
  stage: 'test',
  sentryOriginUrl: 'https://glitchtip.zotorn.it/api',
  sentryDns: 'https://b08eb75970994b8297e1cbfc3756641a@glitchtip.zotorn.it/22',
  sentryEnvironment: 'test',
  version: '',
};
