import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakInitModule } from './base/services/keycloak/keycloak-init.module';
import { StoreInitModule } from './base/services/store/store-init.module';
import { HttpClientModule } from '@angular/common/http';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { FlashMessageOutletModule } from './base/flash-message/outlet/flash-message-outlet.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { Router } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // init
    KeycloakInitModule,
    StoreInitModule,
    FlashMessageOutletModule,
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      // this custom error handler, handles sentry/glitchtip
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        // dialogOptions: {}
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
