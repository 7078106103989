import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectFlashMessages } from '../store/flash-messages.selector';
import { FlashMessagesActions } from '../store/flash-messages.actions';
import { FlashMessage } from '../../model/flash-message.types';

@Injectable({
  providedIn: 'root',
})
export class FlashMessageStateService {
  constructor(private store: Store) {}

  flashMessages$(): Observable<FlashMessage[]> {
    return this.store.select(selectFlashMessages);
  }

  addFlashMessage(flashMessage: FlashMessage): void {
    this.store.dispatch(
      FlashMessagesActions.addFlashMessageAction({ flashMessage }),
    );
  }

  removeFlashMessage(uuid: string): void {
    this.store.dispatch(
      FlashMessagesActions.removeFlashMessageAction({ uuid }),
    );
  }
}
